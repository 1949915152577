.about-me-card {
  box-shadow: 0 0 10px -3px #1f2235;
}

.about-me-picture {
  background: url(../../../assets/about-me/northCarolina.jpg) center/cover
    no-repeat;
}

.highlights-heading {
  font-family: 'Poppins Bold';
}

@media only screen and (max-width: 1110px) {
  .about-me-parent {
    width: 88%;
  }
}

@media only screen and (max-width: 900px) {
  .about-me-picture {
    display: none;
  }

  .about-me-details {
    width: 100%;
  }
}

@media only screen and (max-width: 466px) {
  .about-me-btns {
    display: flex;
    flex-direction: column;
  }
}
