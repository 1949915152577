.landing-details-name {
  font-family: 'Poppins SemiBold';
}

.landing-details-summary h1 {
  font-family: 'Poppins LightItalic';
}

.tagline {
  font-family: 'Poppins Light';
  font-size: 16px;
  overflow-wrap: break-word;
}

.profile-picture-actual {
  background: url(../../../../assets/about-me/profilePic.jpg) top/cover
    no-repeat;
}

@media screen and (max-width: 1110px) {
  .profile-picture-container {
    margin: 0 0 0 80px;
  }

  .landing-details-name {
    font-size: 20px;
  }

  .landing-details-summary .intro-text {
    font-size: 12px;
  }

  .cta-btn {
    width: 120px;
    padding: 11px 0;
  }

  .divider-parent {
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (max-width: 910px) {
  .profile-picture-container {
    height: 320px;
    width: 320px;
    margin: 0 0 0 4px;
  }
}

@media screen and (max-width: 810px) {
  .profile-picture-container {
    margin: 0 !important;
  }

  .landing-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
  }

  .cta-btn-container {
    display: flex;
    justify-content: space-evenly;
  }

  .landing-details {
    margin: 25px 0 0;
  }

  .cta-btn-container,
  .landing-details-summary {
    text-align: center;
  }
}

@media screen and (max-width: 375px) {
  .profile-picture-container {
    height: 275px;
    width: 275px;
    margin-top: -90px;
  }

  .landing-details {
    width: 90%;
  }
}

/* Divider styling below */

@media only screen and (max-width: 1110px) {
  .divider-parent {
    display: flex;
    align-items: flex-end;
  }
}
