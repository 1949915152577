@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  outline: none;
}

@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Thin.ttf');
}

body {
  margin: 0;
  overflow-y: overlay;
  font-family: 'Poppins Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #457b9d;
  border-radius: 10px;
}

.cta-btn {
  padding: 14px 0;
  width: 160px;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Poppins SemiBold';
}

.cta-btn:active {
  transform: translateY(2px);
}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}
