.contact-form {
  box-shadow: 0 0 10px -2px #1f2235;
}

.contact-submit-btn {
  transition: 0.2s;
}

@media screen and (max-width: 700px) {
  .contact-form {
    width: auto;
  }
}
