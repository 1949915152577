.exp-details-carousel {
  transition: transform 1s ease-out;
}

.option-label {
  font-size: 14px;
  white-space: nowrap;
  font-family: 'Poppins SemiBold';
}

.selected-option {
  color: white;
  width: 100%;
}

.exp-sub-heading {
  font-family: 'Poppins SemiBold';
  color: #122035;
  margin: 10px 0 0 0;
}

@media only screen and (max-width: 1110px) {
  .exp-card {
    width: 880px;
  }

  .exp-bullet-details {
    padding: 0 0 0 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .exp-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bullets {
    width: 90%;
  }

  .exp-card {
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    align-items: center;
    width: 90%;
  }

  .exp-bullet-details {
    height: 360px;
    width: fit-content;
    padding: 0;
  }

  .exp-options {
    width: 100%;
    margin: 30px 0;
  }

  .selected-option {
    width: 100%;
  }

  .code-skills-container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .exp-desc {
    font-size: 14px;
  }
}
