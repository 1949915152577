.project-card {
  box-shadow: 0 0 10px -3px #1f2235;
}

.project-picture {
  background-position: center;
  background-repeat: no-repeat;
}

.highlights-heading {
  font-family: 'Poppins Bold';
}

@media only screen and (max-width: 1110px) {
  .latest-parent {
    width: 88%;
  }
}

@media only screen and (max-width: 900px) {
  .project-picture {
    display: none;
  }

  .project-card-left {
    width: 100%;
  }
}

@media only screen and (max-width: 466px) {
  .project-btns {
    display: flex;
    flex-direction: column;
  }
}
